/** @jsx jsx */

import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { jsx } from 'theme-ui'

interface MarketoFormProps {
  containerStyles?: any
  formId: string
}

const MarketoForm: FC<MarketoFormProps> = ({ containerStyles, formId }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    if (isLoaded && MktoForms2) {
      MktoForms2.loadForm(`//get.carta.com`, `214-BTD-103`, formId)
    }
  }, [formId, isLoaded])

  useEffect(() => {
    if (isRendered) {
      const rows = document.querySelectorAll(`.mktoFormRow`)

      rows.forEach((row) => {
        const isRowWithCheckbox = row.querySelector(`.mktoPlaceholderExpressedOptin__c`) !== null

        if (isRowWithCheckbox) {
          row.classList.add(`row-with-checkbox`)
        }
      })
    }
  }, [isRendered])

  // TODO: Find a safer way to enure Marketo script has loaded
  setTimeout(() => {
    setIsLoaded(true)
  }, 1000)

  setTimeout(() => {
    setIsRendered(true)
  }, 2000)

  return (
    <section sx={containerStyles}>
      <Helmet>
        <script src="//get.carta.com/js/forms2/js/forms2.min.js" onLoad={() => setIsLoaded(true)}></script>
      </Helmet>

      <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, py: 5, px: 4 }}>
        <div sx={{ maxWidth: `75ch` }}>
          <h1 sx={{ mt: 0, fontSize: 5 }}>CartaX is here.</h1>
          <p sx={{ mb: 5, fontSize: `1.125rem` }}>
            Carta has built the infrastructure to manage and value private assets. Now we’ve built the marketplace to
            transact in them—CartaX. Learn more about how CartaX is bringing liquidity to the private markets and
            helping companies unlock the value of ownership.
          </p>
        </div>

        <form
          id={`mktoForm_${formId}`}
          sx={{
            minHeight: 600,
            mx: `0 !important`,
            maxWidth: `none !important`,
            fontSize: `inherit !important`,
            fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important`,
            color: `inherit !important`,

            [`&.mktoForm`]: {
              [`.mktoLabel, .mktoLabel.mktoHasWidth`]: {
                mb: 2,
                fontWeight: `500 !important`,
                fontFamily: `inherit !important`,
              },

              [`&.mktoForm input[type=text], &.mktoForm input[type=url], &.mktoForm input[type=email], &.mktoForm input[type=tel], &.mktoForm input[type=number], &.mktoForm input[type=date], &.mktoForm select.mktoField`]:
                {
                  borderColor: `#aeaeae !important`,
                  p: `5px 13px !important`,
                  fontFamily: `inherit !important`,
                  boxShadow: `none`,

                  [`:focus`]: {
                    borderColor: `#000 !important`,
                  },
                },

              [`select.mktoField`]: {
                background: `#fff url(http://get.carta.com/js/forms2/images/arrow-down-bk.png) calc(100% - 0.5rem) center no-repeat !important`,
              },

              [`.mktoHasWidth`]: {
                maxWidth: `100%`,
                mx: `auto`,
              },

              [`.mktoButtonRow .mktoButtonWrap .mktoButton`]: {
                maxWidth: 640,
                mx: `auto`,
                display: `block`,
                fontWeight: 600,
                fontSize: `1rem !important`,
                textTransform: `lowercase`,

                [`:first-letter`]: {
                  textTransform: `uppercase`,
                },
              },

              [`.mktoFieldDescriptor.mktoFormCol`]: {
                [`@media (min-width: 768px)`]: {
                  width: `calc(50% - 5px) !important`,
                },
              },

              [`.mktoFormCol:nth-last-of-type(2):first-of-type`]: {
                width: `100% !important`,
              },

              [`.row-with-checkbox`]: {
                [`label#LblExpressedOptin__c`]: {
                  display: `none !important`,
                },

                [`input#ExpressedOptin__c`]: {
                  opacity: `1`,
                  width: `15px`,
                  height: `15px`,
                  border: `0px`,
                },

                [`.mktoFormCol`]: {
                  width: `calc(100% - 26px - 5px) !important`,
                  fontSize: `15px`,
                },

                [`.mktoCheckboxList`]: {
                  paddingTop: `0.2em`,
                },

                [`.mktoFieldDescriptor.mktoFormCol`]: {
                  width: `26px !important`,
                },

                [`.mktoHtmlText`]: {
                  width: `auto !important`,
                },
              },

              [`.mktoError`]: {
                [`.mktoErrorArrow`]: {
                  borderColor: `#fad2d2`,
                  background: `#ffe9e9`,
                },

                [`.mktoErrorMsg`]: {
                  borderColor: `#fad2d2`,
                  textShadow: `none`,
                  color: `#932424`,
                  background: `#ffe9e9`,
                  boxShadow: `none`,
                },
              },
            },
          }}
        >
          {!isLoaded && <i>Loading form…</i>}
        </form>

        <div sx={{ mt: 5 }}>
          <p sx={{ mt: 0, pt: 3 }}>
            By joining the waitlist, you agree to opt-in to emails from CCMX. You will have the opportunity to opt-out
            in the future.
          </p>

          <p sx={{ mb: 0, color: `gray5_slate4` }}>
            ©️ 2021 Carta Capital Markets, LLC (“CCMX”) | All Rights Reserved | CCMX member FINRA/SIPC | CCMX undertakes
            no obligation to update content herein | No business, investment, tax or legal advice is provided by CCMX |
            Potential investors are advised to conduct their own due diligence and consult with their tax, legal, and
            financial advisors with respect to any investment | All securities involve risk and may result in partial or
            total loss | Investments in private securities are speculative, illiquid, and involve a high degree of risk,
            including the possible loss of your entire investment | Access to third-party content from this site,
            including content of CCMX’s parent company, Carta, Inc., may be available through framed areas or
            hyperlinks. Access to such content is for informational purposes only and does not constitute an explicit or
            implicit endorsement by CCMX of such content as to accuracy, timeliness, completeness or usefulness | There
            is no guarantee that a private company will conduct an initial public offering or provide an alternative
            exit strategy for your invested capital | Images are illustrative and may differ from application experience
          </p>
        </div>
      </div>
    </section>
  )
}

export default MarketoForm
